(function () {
  'use strict';

  angular
    .module('dashboard.sessions')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard.sessions', {
        url: '/sessions',
        templateUrl: 'dashboard/sessions/sessions.tpl.html',
        controller: 'SessionsCtrl',
        controllerAs: 'sessions'
      });
  }
}());
